.banner-text {
	text-align: center;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	color: #111111;
}

.sub-heading {
	margin-top: 32px;
	margin-bottom: 32px;
}

.card-attribute {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  text-align: center;
	min-height: 256px;
	max-height: 256px;
  width: 512px;
  padding: 16px;
	margin-top: 32px;
}

.skill-attribute {
	margin: 8px;
}

.card-stat {
	height: 550px;
}

.stat-value {
	font-weight: bold;
	font-size: 2em;
}

.attribute-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
	flex-wrap: wrap;
}

.stat-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.project-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.programming-stat {
  padding: 32px;
}
