.accordion-container {
	margin: 32px;
}

.accordion-header {
	flex-basis: 33.33%;
	flex-shrink: 0;
}

.accordion-header a, .accordion-header a:visited {
	text-decoration: none;
	font-weight: bold;
	color: #3F51B5;
}

.past-project-list:nth-child(2n) {
	background-color: #FFFFFF;
	background-color: rgba(0, 0, 0, 0.05);
	padding: 16px;
}

.past-project-list:nth-child(2n+1) {
	background-color: #FFFFFF;
	background-color: rgba(0, 0, 0, 0.13);
	padding: 16px;
}
