.section-container {
	margin: 24px 0 24px 0;
}

.padding-tile {
	margin-top: 32px;
	margin-bottom: 32px;
}

.content-spacing {
	height: auto;
}

.employer-title {
	margin-bottom: 8px;
	display: block;
	font-weight: bold;
}

h3, h4 {
	margin: 0;
}

md-card {
	flex-wrap: wrap;
}

.list-info {
	padding-right: 4px;
}

.list-info-header {
	margin-left: 40px;
}

.tile-content {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding-left: 32px;
}

.pf-header {
	background-color: #3f51b5;
	color: #fafafa;
	font-size: 24px;
	font-weight: 500;
	padding: 8px;
}

.pf-header-content {
	padding: 8px;
}

.emblem {
	width: 150px;
	height: 150px;
}

.lang-label {
	margin: 1px;
}

.no-logo {
	width: 64px;
	height: 64px;
}

.skill-cover {
	background: #FFFFFF;
	padding: 16px;
}

.skill-display img {
	margin: 16px;
	flex-wrap: wrap;
}

.edu-header > span {
	padding: 0 8px 0 8px;
}

md-icon {
	vertical-align: text-top;
}
