.footer-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.links-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.footer-text {
  font-size: 12px;
}

.footer-icon {
	font-size: 16px;
	margin: 8px;
	color: rgba(0, 0, 0, 0.54);
}

.footer-icon:hover {
	color: rgba(0, 0, 0, 0.38);
}

.footer-icon:visited {
	color: rgba(0, 0, 0, 0.54);
}
